.loader-small {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 10px;
  border-top: 3px solid rgba(164, 164, 164, 0.2);
  border-right: 3px solid rgba(164, 164, 164, 0.2);
  border-bottom: 3px solid rgba(164, 164, 164, 0.2);
  border-left: 3px solid rgba(164, 164, 255, 0.45);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader-animation 2.3s infinite linear;
  animation: loader-animation 2.3s infinite linear;
}
.loader-large {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 20px;
  border-top: 8px solid rgba(164, 164, 164, 0.2);
  border-right: 8px solid rgba(164, 164, 164, 0.2);
  border-bottom: 8px solid rgba(164, 164, 164, 0.2);
  border-left: 8px solid rgba(100, 150, 255, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader-animation 2.3s infinite linear;
  animation: loader-animation 2.3s infinite linear;
}

.loader-large-text {
  position:relative;
  margin-right: -165px;
  right:145px;
  color: rgba(100,150,200, .5);
  font-style: italic;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 42px;
  font-weight: bold;
}

@-webkit-keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}