.debug-header{ 
  font-family: Consolas; 
  font-size: 14px; 
  color: #808080; 
  margin-bottom: 10px;
}
.debug-base { 
  margin: 5px; 
  padding: 5px; 
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
  color:black; 
  font-size: 10px; 
  border: solid black 1px;
  background-color: aliceblue; 
  text-align: left; 
  min-height: 100px; 
  flex: '1 0 content';
  position: relative;
}

.debug-admin { 
  background-position: center; 
  background-origin: content-box; 
  height:36px; width:36px; 
  position: absolute;
  bottom: 5px;
  right: 5px; 
  cursor: pointer; 
  background-image: url(/content/images/admin.svg); 
}
.debug-base span{ color: blue; }
.debug-theme{ display: inline-flex; width: 50px; position: relative; right: 97px; margin-right: -97px; font-size: 12px;
  font-weight: bold; padding-left: 8px; height: 13px; color:black;
}
.debug-base select{ border:  solid blue 1px; margin: 2px; padding: 1px; }
.debug-colors select{ border: 1px; border-style: solid; }
.debug-colors-el{ font-size: 12px; }
.themeDiv{ padding-left: 30px; }