html { overflow-y:scroll !important;}
html,body { height:100%; }

.testColor { color: #fffcd5; }
.baseLink {color:blue; cursor:pointer;}
.baseLink:hover {color:dodgerblue; text-decoration: underline; }

body { margin: 0; padding: 0; }
h1{ font-size: 16px; }
h2{ font-size: 20px; font-weight: 900; }
label{ font-size: 15px;}

input[type='text']{ font-size: 15px; height: 26px; outline: none; }
input[type='text']::-webkit-input-placeholder { font-style: italic; color:#C0C0C0; font-size: 15px;}
input[type='text']:-moz-placeholder { font-style: italic; font-size: 12px; color:#C0C0C0;}
input[type='text']::-moz-placeholder { font-style: italic; font-size: 12px; color:#C0C0C0;}
input[type='text']:-ms-input-placeholder { font-style: italic; font-size: 12px; color:#C0C0C0;}

input[type='text']:hover::-webkit-input-placeholder { color:#3090FF; }
input[type='text']:hover:-moz-placeholder { color:#3090FF; }
input[type='text']:hover::-moz-placeholder:hover { color:#3090FF; }
input[type='text']:hover:-ms-input-placeholder:hover { color:#3090FF; }

input[type='text']:focus::-webkit-input-placeholder { color:transparent; }
input[type='text']:focus:-moz-placeholder { color:transparent; }
input[type='text']:focus::-moz-placeholder:hover { color:transparent; }
input[type='text']:focus:-ms-input-placeholder:hover { color:transparent; }

input[type=checkbox], input[type=radio] { vertical-align: middle; position: relative; bottom: 2px; }